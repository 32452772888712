import { NavLink as PrimativeNavLink } from "@remix-run/react";
import type { ComponentProps } from "react";
import { forwardRef } from "react";

import { useGlobalContext } from "../../global/UIProvider.js";

type NavLinkProps = ComponentProps<typeof PrimativeNavLink>;

const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  const { prefetch } = useGlobalContext();

  const propsWithDefaults: NavLinkProps = {
    prefetch,
    ...props,
  };

  return <PrimativeNavLink ref={ref} {...propsWithDefaults} />;
});

export default NavLink;
